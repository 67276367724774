@import './base.scss';

$background: #fafafa;
.m2-menu {
  position: fixed;
  bottom: 0.5rem;
  left: 0rem;
  @media(min-width: 768px) {
    bottom: 1rem;
  }
  a {
    padding: 0.6rem;
    @media(min-width: 768px) {
      padding: 1.5rem;
    }
    font-size: 2rem;
  }
}

.m2-shelf {
  width: 20rem;
  height: 100vh;
  background-color: $background;
  position: fixed;
  top: 0px;

  .m2-profile {
    position: inherit;
    padding: 1.5rem;
    width: 20rem;

    .m2-profile-photo {
      margin-top: 1.5rem;
      width: 4rem;
      height: 4rem;
      img {
        border-radius: 2rem;
        border: 1px solid black;
      }
    }

    .m2-username {
      font-weight: bold;
      margin-top: 1.5rem;
    }

    .m2-email {
      margin-top: 0.5rem;
    }

    .m2-switch-user {
      margin-top: 1.5rem;

      button {
        width: 45%;
        &:first-child {
          margin-right: 10%;
        }
      }
    }
  }

  .m2-menu-footer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 1.5rem;
    a {
      padding-left: 1.5rem;
    }
  }
}

.m2-menu-links {
  margin-top: 18rem;
  div {
    padding: 1.5rem;
    padding-bottom: 0;
    a {
      padding-bottom: 1.5rem;
      font-size: 1.1rem;
      border-bottom: 1px solid darken($background, 8%);
      display: block;
    }
    &:first-child {
      padding-top: 0;
      a {
        padding-top: 1.5rem;
        border-top: 1px solid darken($background, 8%);
      }
    }
  }
}

.m2-close {
  position: absolute;
  top: 0.3rem;
  right: 1rem;
  font-size: 1.5rem;
}
