$primary: #3137FD;
$white: #ffffff;
$link: $primary;
$fontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$pretty--color-success: $primary;
$pretty--color-default: $primary;
$text: #474C6D;
$darkModePrimary: #FF1DCE;
$darkModeDark: #212127;

@import '~bulma';
@import '~bulma-switch';
@import './dialog-polyfill';

body {
  font-size: 14px;
  @include tablet {
    font-size: 16px;
  }
  &.m2-dark-mode {
    background-color: $darkModeDark;

    #m2-autocomplete {
      background-color: lighten($darkModeDark, 10%);
      color: #cdced0;
      border: 1px solid lighten($darkModeDark, 15%);

      div:not(:last-child) {
        border-bottom: 1px solid lighten($darkModeDark, 15%);
      }
    }

    .m2-doc,.m2-ellipsis {
      color: #cdced0;
      a,.m2-mention-hashtag {
        color: $darkModePrimary;
        &:hover {
          color: lighten($darkModePrimary, 10%);
        }
      }
    }

    .m2-bookmark hr {
      border-bottom: 4px solid $darkModePrimary;
    }

    .m2-ellipsis {
      color: $darkModePrimary;
      &:hover {
        color: lighten($darkModePrimary, 10%);
      }
    }

    .m2-doc {
      &.content {
        h1,h2,h3,h4,h5,h6 {
          color: #cdced0;
        }
      }

      code {
        background-color: #171515;
        color: #778899;
      }

      .m2-edit-mode {
        border-left: 3px solid rgba($darkModePrimary, 0.4);
      }

      &.m2-syncing .m2-edit-mode {
        border-left: 3px solid rgba($darkModePrimary, 0.15);
      }

      strong {
        color: white;
      }

      hr {
        background-color: #4d4d52;
      }

      &.content blockquote {
        background-color: #2a2a2d;
        border-left: 5px solid #3e3e42;
      }

      &.content table thead th {
        color: #cdced0;
      }
    }
  }
}

.switch[type=checkbox]:checked + label::before, .switch[type=checkbox]:checked + label:before {
    background: $primary;
}

a:hover {
  color: darken($primary, 20%);
}

.content pre {
  white-space: pre-wrap;
}


.modal.is-active {
  display: block;
  height: 100vh;
  .modal-card {
    display: block;
    height: inherit;
    margin-left: 0px;
    overflow: visible;
    .modal-card-head {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-body {
      height: inherit;
    }
  }

  .modal-card-foot {
    display: block;
    position: fixed;
    bottom: 0px;
    width: 100%;
  }

  @include tablet {
    display: flex;
    height: auto;
    .modal-card {
      display: flex;
      margin-left: auto;
      overflow: hidden;
      .modal-card-head {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .modal-card-foot {
      display: flex;
      position: unset;
      bottom: unset;
      width: unset;
    }
  }
}
