@import './base.scss';

@keyframes loading {
    from {left: 50%; width: 0;z-index:100;}
    33.3333% {left: 0; width: 100%;z-index: 10;}
    to {left: 0; width: 100%;}
}

.m2-loading {
  position: fixed;
  margin-top: 0;
  z-index: 2;
  width: 100%;
  height: 5px;
  background-color: lighten($primary, 10%);
  .bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
    &:nth-child(1) {
      background-color: lighten($primary, 30%);
      animation: loading 3s linear infinite;
    }
    &:nth-child(2) {
      background-color: lighten($primary, 50%);
      animation: loading 3s linear 1s infinite;
    }
    &:nth-child(3) {
      background-color: lighten($primary, 10%);
      animation: loading 3s linear 2s infinite;
    }
  }
}
