@import './base.scss';

.m2-load-screen {
  text-align: center;
  margin-top: 35vh;
  .title.is-1 {
    font-size: 2.2rem;
    @include tablet {
      font-size: 3rem;
    }
    img {
      width: 3.5rem;
      padding: 0 1rem 0 1rem;
      @include tablet {
        width: 4rem;
      }
    }
  }
}

.m2-splash-container {
  background: linear-gradient(to bottom, #ffffff, #f2f1f3);
  min-height: 100vh;
}

.m2-splash {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem;
  text-align: center;

  .m2-hero {
    margin-top: 15vh;
    margin-bottom: 10vh;
    .title.is-1 {
      font-size: 2.2rem;
      @include tablet {
        font-size: 3rem;
      }
      img {
        width: 3.5rem;
        padding: 0 1rem 0 1rem;
        @include tablet {
          width: 4rem;
        }
      }
  }
  }

  .m2-cta {
    margin-top: 2rem;
    .button {
      width: 100%;
      margin-top: 1rem;
      &:hover {
        background-color: transparent !important;
        color: $primary !important;
        box-shadow: 0px 0px 5px $primary;

      }
      @include tablet {
        width: 15rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }}
  }

  .m2-tiles {
    margin-top: 3rem;
    p {
      text-align: left;
    }
    .columns {
      margin-top: 2rem;
    }

    hr {
      @include tablet {
        display: none;
      }
      overflow: visible; /* For IE */
      padding: 0;
      border: none;
      border-top: medium double #adadad;
      color: #333;
      text-align: center;
      &:after {
          content: "§";
          display: inline-block;
          position: relative;
          top: -0.7em;
          font-size: 1.5em;
          padding: 0 0.25em;
          background: #f5f5f6;
      }
    }
  }
}
