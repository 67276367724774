@import './base.scss';

.m2-docs {
  section div {
    text-align: right;
    margin-bottom: 1rem;
  }

  td:first-child {
    width: 2rem;
    .select {
      width: 2rem;
      vertical-align: middle;
      margin: 0;
      select {
        background-color: transparent;
        border: none;
        &:focus {

        }
      }
    }
  }

  .m2-is-current-doc {
    font-weight: bold;
  }

  td input {
    border: none;
    border-bottom: 1px dotted gray;
    font-size: 1rem;
    background-color: inherit;
    &:focus {
      outline: none;
    }
  }

  .m2-import {
    input {
      display: none;
    }
    .button.is-text {
      margin-right: 0.5rem;
    }
  }

  .m2-footer {
    text-align: right;
    a {
      color: lightgrey;
    }
  }
}

.m2-search {
  .m2-search-button {
    width: auto;
  }

  .modal-card-body {
    height: -webkit-fill-available !important;
    padding-bottom: 1.5rem;
    @include tablet {
      height: inherit !important;
    }
  }

  .m2-search-results {
    margin-top: 2rem;
    min-height: 15rem;
    .m2-search-result {
      cursor: pointer;
      padding: 1rem;
      border-top: 1px solid darken(white, 3%);
      &:hover {
        background-color: darken(white, 2%);
      }
    }
  }
}


.m2-about {
  footer  {
    text-align: left;
    a {
      margin-right: 4vw;
      @include tablet {
        margin-right: 2rem;
      }
    }

    .m2-coffee {
      position: absolute;
      right: 0px;
      bottom: 0.6rem;
      @include tablet {
        bottom: 0.3rem;
      }
      img {
        width: 8rem;
        @include tablet {
          width: 180px;
        }
      }
    }
  }

  section p {
    margin-bottom: 1.5rem;
  }

  .m2-me {
    margin-top: 3rem;
    text-align: center;
    .m2-profile {
      width: 6rem;
      border-radius: 6rem;
      border: 2px solid $primary;
    }
  }
}

.m2-help {
  .content {
    height: -webkit-fill-available !important;
    padding-bottom: 1.5rem;
  }
}

.m2-settings {
  .field {
    padding-left: 2rem;
    padding-bottom: 0.75rem;
    p {
      padding-bottom: 0.5rem;
    }
  }
}
